<template>
  <div class="content">
    <el-form inline :model="search" class="tb-form">
      <el-form-item label="楼层名称：">
        <el-input v-model="search.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button class="tb-button" type="primary" @click="add">
        <i class="tb-icon-plus"/>添加楼层
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        class="tb-table"
        @row-dblclick="toDetail"
      >
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="activity_name" label="楼层名称" min-width="120" />
        <el-table-column prop="sort" label="排序"/>
        <el-table-column prop="style_type" label="楼层样式"/>
        <el-table-column prop="last_modifier" label="创建人"/>
        <el-table-column prop="statusname" label="状态"/>
        <el-table-column prop="created" label="创建时间" min-width="120" />
        <el-table-column prop="updated" label="更新时间" min-width="120" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="`/indexflow/add?id=${scope.row.id}`" class="edit">编辑</router-link>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="deleteItem(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="tb-pagination"
        layout="prev, pager, next"
        :current-page.sync="page.no + 1"
        :page-size="page.size"
        :total="page.total"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexFlowList',
  data() {
    return {
      search: {
        name: ''
      },
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '首页楼层列表'
    }
    this.$store.commit('changeRoute', currentPage)
    this.getData()
  },
  methods: {
    getData() {
      const {no, size} = this.page
      const url = 'admin/activity/list'
      const params = {
        page_no: no,
        page_size: size,
        position_type: 4,
        name: this.search.name
      }
      this.$https.get(url, {params}).then(data => {
        if (!data) return
        this.page.total = data.total_count
        data.list.forEach(item => {
          item.created = this.$dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated = item.updated_at ? this.$dayjs(item.updated_at).format('YYYY-MM-DD HH:mm') : ''
          item.statusname = item.status === 0 ? '未生效' : '生效'
        })
        this.tableData = data.list
      })
    },
    onSearch() {
      this.page.no = 0
      this.getData()
    },
    pageChange(no) {
      this.page.no = no - 1
      this.getData()
    },
    deleteItem(item) {
      this.$confirm('是否删除此楼层?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const detailUrl = 'admin/activity/detail?id=' + item.id
        const detail = await this.$https.get(detailUrl)
        if (!detail) return
        const params = Object.assign({}, detail, {status: -1})
        const url = 'admin/activity/edit'
        const data = await this.$https.post(url, params)
        if (data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getData()
        }
      }).catch(() => {})
    },
    add() {
      this.$router.push('/indexflow/add')
    },
    toDetail(row) {
      this.$router.push(`/indexflow/add?id=${row.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
